import Vue from 'vue'
import App from './App.vue'
import Amplify, {API} from 'aws-amplify';
import '@aws-amplify/ui-vue';
import aws_exports from './aws-exports';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import store from './store/store';
require('typeface-montserrat')
import VCalendar from 'v-calendar';

Vue.use(VCalendar);
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Amplify.configure(aws_exports);
Vue.config.productionTip = false

new Vue({
  store,
  async created(){
    const theAmplify = Amplify;
    theAmplify.API = API;
    this.$store.commit('amplifyInstance', theAmplify);
    await this.$store.state.amplifyInstance.Auth.currentAuthenticatedUser();
    if (this.$store.state.amplifyInstance.Auth.user) {
      this.$store.commit('signedIn');
    }
  },
  methods: {
    confirm(opts, callback, callbackCancel = () => {}){
      this.$bvModal.msgBoxConfirm(opts.message, {
          title: opts.title,
          size: 'sm',
          buttonSize: 'sm',
          okVariant: opts.okVariant,
          okTitle: opts.okTitle,
          cancelTitle: opts.cancelTitle,
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
      })
      .then(value => {
          if (value) {
            callback();
          } else {
            callbackCancel();
          }
      })
      .catch(() => {
          // An error occurred
      })
    },
  },
  render: h => h(App),
}).$mount('#app')
