import Vue from 'vue';
import Vuex from 'vuex';
import actions from './actions/actions';
import mutations from './mutations/mutations';

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    app: {
        name: 'ADA Holiday Voicemail Switch',
        version: '0.1 beta',
        logo: require('@/assets/logo.png'),
    },
    signedIn: false,
    currentBreakpoint: '',
    amplifyInstance: null,
    signIn: {
      email: '',
      password: ''
    }
  },
  mutations,
  actions
})

export default store;