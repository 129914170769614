/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createRecord = /* GraphQL */ `
  mutation CreateRecord(
    $input: CreateRecordInput!
    $condition: ModelRecordConditionInput
  ) {
    createRecord(input: $input, condition: $condition) {
      id
      holiday
      holidayStart
      holidayEnd
      voicemailMessage
      enabled
      note
      startTime
      endTime
      timeRanged
      expdate
      createdAt
      updatedAt
    }
  }
`;
export const updateRecord = /* GraphQL */ `
  mutation UpdateRecord(
    $input: UpdateRecordInput!
    $condition: ModelRecordConditionInput
  ) {
    updateRecord(input: $input, condition: $condition) {
      id
      holiday
      holidayStart
      holidayEnd
      voicemailMessage
      enabled
      note
      startTime
      endTime
      timeRanged
      expdate
      createdAt
      updatedAt
    }
  }
`;
export const deleteRecord = /* GraphQL */ `
  mutation DeleteRecord(
    $input: DeleteRecordInput!
    $condition: ModelRecordConditionInput
  ) {
    deleteRecord(input: $input, condition: $condition) {
      id
      holiday
      holidayStart
      holidayEnd
      voicemailMessage
      enabled
      note
      startTime
      endTime
      timeRanged
      expdate
      createdAt
      updatedAt
    }
  }
`;
export const createMainSwitch = /* GraphQL */ `
  mutation CreateMainSwitch(
    $input: CreateMainSwitchInput!
    $condition: ModelMainSwitchConditionInput
  ) {
    createMainSwitch(input: $input, condition: $condition) {
      id
      enabled
      createdAt
      updatedAt
    }
  }
`;
export const updateMainSwitch = /* GraphQL */ `
  mutation UpdateMainSwitch(
    $input: UpdateMainSwitchInput!
    $condition: ModelMainSwitchConditionInput
  ) {
    updateMainSwitch(input: $input, condition: $condition) {
      id
      enabled
      createdAt
      updatedAt
    }
  }
`;
export const deleteMainSwitch = /* GraphQL */ `
  mutation DeleteMainSwitch(
    $input: DeleteMainSwitchInput!
    $condition: ModelMainSwitchConditionInput
  ) {
    deleteMainSwitch(input: $input, condition: $condition) {
      id
      enabled
      createdAt
      updatedAt
    }
  }
`;
