const mutations =  {
    signedIn(state, signedIn = true) {
        state.signedIn = signedIn;
    },
    setCurrentBreakpoint(state){
        const screenWidth = document.documentElement.clientWidth;
        let bp = '';
        if (screenWidth < 768) {
            bp = 'xs';
        } else if (screenWidth >= 768 && screenWidth < 992) {
            bp = "md"
        } else if (screenWidth >= 992 && screenWidth < 1200) {
            bp = "lg"
        } else {
            bp = "xl";
        }

        state.currentBreakpoint = bp;
    },
    amplifyInstance(state, payload) {
        state.amplifyInstance = payload;
    },
    signIn(state, payload) {
        state.signIn = payload;
    }
}

export default mutations;