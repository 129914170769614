<template>
  <div>
    <b-card class="shadow-sm">
        <b-card-body :style="noPad">
            <div class="d-flex px-2">
                <div>
                    <b-form-checkbox v-model="mainSwitch" @change="confirmSwitch" name="check-button" switch>
                    Holiday Check Switch <strong>({{ mainSwitch ? 'ON' : 'OFF' }})</strong>
                    </b-form-checkbox>
                </div>
            </div>
            <div class="d-flex justify-content-between align-items-center px-2">
                <b-button variant="primary" size="sm" :disabled="!mainSwitchGot || !recordsGot" @click="addHoliday">
                    <b-icon variant="light" icon="calendar2-plus" :class="{'mr-2' : bp !== 'xs'}"></b-icon>
                    {{bp !== 'xs' ? 'Add Holiday' : ''}}
                </b-button>
                <b-form-group
                >
                <b-input-group size="sm">
                    <b-input-group-prepend is-text>
                        <b-icon icon="search"></b-icon>
                    </b-input-group-prepend>
                    <b-form-input
                    v-model="search"
                    type="search"
                    id="searchInput"
                    placeholder="Type to Search"
                    @focus="subscribe"
                    ></b-form-input>
                    
                    <b-input-group-append>
                        <b-button :disabled="!search" @click="search = ''">Clear</b-button>
                    </b-input-group-append>
                </b-input-group>
                </b-form-group>
            </div>
            <b-table
                :filter="search"
                :filter-function="filterTable"
                @filtered="onFiltered"
                stacked="md"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :items="items" 
                :busy="tableBusy"
                :current-page="currentPage"
                :per-page="perPage" 
                :fields="fields" 
                class="mt-3 shadow-sm" 
                outlined
                :empty-text="emptyText"
                :empty-filtered-text="emptyText"
                show-empty
                :class="{'min-width-1037' : bp == 'lg' || bp == 'xl'}"
            >
                <template v-slot:cell(#)="data">
                    {{data.index+1}}
                </template>

                <template v-slot:cell(holidayStart)="data">
                    <div v-b-popover.hover.top="prettyTableStartDate(data.item)" title="Start Time">
                        {{truncate(prettyTableStartDate(data.item), 21)}}
                    </div>
                </template>

                <template v-slot:cell(holidayEnd)="data">
                    <div v-b-popover.hover.top="prettyTableEndDate(data.item)" title="End Time">
                        {{truncate(prettyTableEndDate(data.item), 21)}}
                    </div>
                </template>

                <template v-slot:cell(holiday)="data">
                    <div v-b-popover.hover.top="data.item.holiday" title="Holiday">
                        {{truncate(data.item.holiday, 10)}}
                    </div>
                </template>

                <template v-slot:cell(voicemailMessage)="data">
                    <div v-b-popover.hover.top="data.item.voicemailMessage" title="Voicemail Message">
                        {{truncate(data.item.voicemailMessage)}}
                    </div>
                </template>
                <template v-slot:cell(note)="data">
                    <div v-b-popover.hover.top="data.item.note" title="Note">
                        {{truncate(data.item.note, 10)}}
                    </div>
                </template>
                <template v-slot:cell(enabled)="data">
                    <b-icon class="mr-1" :icon="data.item.enabled ? 'calendar2-check' : 'calendar2-x'"></b-icon>
                    {{data.item.enabled ? 'Yes' : 'No'}}
                </template>
                <template v-slot:cell(action)="data">
                    <div>
                        <b-button-toolbar>
                            <b-button-group size="sm" class="mr-1">
                                <b-button title="Edit" @click="editHoliday(data.item)" variant="primary">
                                    <b-icon 
                                        icon="pencil"
                                        aria-hidden="true"
                                    ></b-icon>
                                </b-button>
                                <b-button title="Remove" @click="removeConfirm(data.item)" variant="danger">
                                    <b-icon 
                                    icon="trash"
                                    aria-hidden="true"></b-icon>
                                </b-button>
                            </b-button-group>
                        </b-button-toolbar>
                    </div>
                </template>
                <template v-slot:table-busy>
                    <div class="text-center text-primary my-2 d-flex align-items-center justify-content-center">
                        <b-spinner small class="mx-2"></b-spinner>
                        <p class="m-0 p-0">Loading public holiday records...</p>
                    </div>
                </template>
            </b-table>
            <div class="my-2">
                <b-form-group
                    label="Per page"
                    label-cols-lg="10"
                    label-cols-md="10"
                    label-cols-sm="10"
                    label-align-sm="right"
                    label-size="sm"
                    label-for="perPageSelect"
                    >
                    <b-form-select
                        v-model="perPage"
                        id="perPageSelect"
                        size="sm"
                        :options="pageOptions"
                    ></b-form-select>
                </b-form-group>
            </div>
            <b-row class="align-items-center">
                <b-col cols="12" class="my-1">
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        align="fill"
                        size="sm"
                        class="my-0"
                    ></b-pagination>
                </b-col>
            </b-row>
        </b-card-body>
    </b-card>


    <b-modal
      size="lg"
      @hide="stopAudio"
      v-model="editModalShow"
      id="edit-modal"
      :title="`Edit Holiday: ${editHolidayName}`"
    >
      <template v-slot:modal-footer="{ cancel }">
        <b-button size="sm" variant="primary" squared :disabled="!editFormValid || editingHoliday" @click="editHolidaySubmit">
            <b-icon v-if="editingHoliday" icon="arrow-clockwise" animation="spin" font-scale="1"></b-icon>
            Edit Holiday
        </b-button>
        <b-button size="sm" squared variant="secondary" @click="cancel()">
            Cancel
        </b-button>
      </template>
      <div>
        <b-form-group
          label-for="holiday-input"
          invalid-feedback="Holiday name is required"
        >
            <template slot="label">
                <div class="d-flex justify-content-between">
                    <div>Holiday Name: (Required)</div>
                    <b-icon 
                        class="cursor-pointer" 
                        v-b-popover.hover.top="'Name of the holiday (just for identification purposes, not used anywhere else).'"
                        icon="question-circle">
                    </b-icon>
                </div>
            </template>
          <b-form-input
            id="holiday-input"
            v-model="editRow.holiday"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label-for="holiday-dates"
          invalid-feedback="Holiday date is required"
          label-class="mb-1 mt-3"
          class="mb-3"
          description="Click twice: first click selects the start date, second click selects the end date."
        >
            <template slot="label">
                <div class="d-flex justify-content-between">
                    <div>Holiday Date (Start - End): (Required)</div>
                    <b-icon 
                        class="cursor-pointer" 
                        v-b-popover.hover.top="'The start and end date of the holiday (pick the same date for both if it\'s one day only). If this holiday is enabled, people calling the Amazon Connect number on the start and end date will hear the text in the Voicemail Message field playing as a speech before they hear a beep and their voicemail recorded.'"
                        icon="question-circle">
                    </b-icon>
                </div>
            </template>
            <v-date-picker
                id="holiday-dates"
                mode='range'
                :popover="{ placement: 'bottom', visibility: 'click' }"
                :input-props="{readonly: true}"
                v-model='editRow.dateRange'
                :disabled-dates="editDisabledDates"
                :attributes="editHolidayAttributes"
                color="purple"
            >
                
            </v-date-picker>
        </b-form-group>


        <b-form-group
            label-for="full-day-input"
            label-class="mb-1 mt-3"
            class="mb-3"
            description="Set this to disabled if you want the holiday to take full 24 hours of the date range"
        >
            <template slot="label">
                <div class="d-flex justify-content-between">
                    <div>Time ranged:</div>
                    <b-icon 
                        class="cursor-pointer" 
                        v-b-popover.hover.top="'Set this to disabled if you want the holiday to take full 24 hours of the date range'"
                        icon="question-circle">
                    </b-icon>
                </div>
            </template>
            <b-form-checkbox v-model="editRow.timeRanged" switch size="lg"><small>{{editRow.timeRanged ? 'Enabled' : 'Disabled'}}</small></b-form-checkbox>
        </b-form-group>
        <b-form-group
            label-for="start-time"
            invalid-feedback="Start time is required"
            label-class="mb-1 mt-3"
            class="mb-3"
            v-if="editRow.timeRanged"
        >
            <template slot="label">
                <div class="d-flex justify-content-between">
                    <div>Start Time: (Required) {{!editRow.dateRange ? '(Select Holiday Date first)' : ''}}</div>
                    <b-icon 
                        class="cursor-pointer" 
                        v-b-popover.hover.top="'The start time of the holiday. If this holiday is enabled, people calling the Amazon Connect number on the start and end date (starting on this time) will hear the text in the Voicemail Message field playing as a speech before they hear a beep and their voicemail recorded.'"
                        icon="question-circle">
                    </b-icon>
                </div>
            </template>
            <div>
                <vue-timepicker 
                    @input="checkEditRowStartTimeDone"
                    v-model="editRow.startTime"
                    :disabled="!editRow.dateRange" 
                    format="h:mm A"
                ></vue-timepicker>
            </div>
        </b-form-group>

        <template
            v-if="editRow.timeRanged"
        >
            <b-form-group
                label-for="start-time"
                invalid-feedback="End time is invalid"
                label-class="mb-1 mt-3"
                class="mb-3"
            >
                <template slot="label">
                    <div class="d-flex justify-content-between">
                        <div>End Time: (Required) {{!editRow.dateRange ? '(Select Holiday Date first)' : ''}}</div>
                        <b-icon 
                            class="cursor-pointer" 
                            v-b-popover.hover.top="'The end time of the holiday. If this holiday is enabled, people calling the Amazon Connect number on the start and end date (ending on this time) will hear the text in the Voicemail Message field playing as a speech before they hear a beep and their voicemail recorded.'"
                            icon="question-circle">
                        </b-icon>
                    </div>
                </template>
                <div>
                    <vue-timepicker 
                        v-model="editRow.endTime" 
                        format="h:mm A"
                        :disabled="!editRow.dateRange"
                        @input="checkEditRowTimeValid"
                    ></vue-timepicker>
                </div>
            </b-form-group>

            <div
                v-if="editRowTimeValidator"
                class="mb-5"
                :key="'edit_' + JSON.stringify(editRow.dateRange)"
            >
                The holiday is set to start on <strong>{{prettyDate(editRow.dateRange.start)}}</strong> at <strong>{{prettyTime(editRow.startTime)}}</strong> and end on <strong>{{prettyDate(editRow.dateRange.end)}}</strong> at <strong>{{prettyTime(editRow.endTime)}}</strong>
            </div>
        </template>

         <b-form-group
          label-for="holiday-textarea"
          invalid-feedback="Voicemail message is required"
          class="mb-3"
        >
            <template slot="label">
                <div class="d-flex justify-content-between">
                    <div>Voicemail Message: (Required)</div>
                    <b-icon 
                        class="cursor-pointer" 
                        v-b-popover.hover.top="'The Voicemail Message which will be converted into speech that will play to people calling the Amazon Connect number on the start to end date (if this holiday is enabled) before they hear a beep and their voicemail recorded.'" 
                        icon="question-circle">
                    </b-icon>
                </div>
            </template>
          <b-form-textarea
            id="holiday-textarea"
            v-model="editRow.voicemailMessage"
            :disabled="generatingAudio || playingAudio"
            @input="audioStream = null"
            rows="4"
            required
          ></b-form-textarea>
          <div class="d-flex justify-content-end">
            
            <b-button v-if="!audioStream" :disabled="generatingAudio || editRowDisabled" size="sm" class="mt-1 mr-1" @click="generateTextToSpeech(editRow.voicemailMessage)">
                <b-icon icon="volume-up"></b-icon> {{generatingAudio ? 'Generating Audio...' : 'Play Audio Preview'}}
            </b-button>

            <b-button v-if="audioStream" size="sm" :disabled="editRowDisabled" class="mt-1 mr-1" @click="playStopAudioStream">
                <b-icon icon="volume-up"></b-icon> {{playingAudio ? 'Stop Audio' : 'Play Audio Preview'}}
            </b-button>

            <b-button @click="defaultVoicemailMessageConfirm(editRow)" class="mt-1" size="sm">Use Default Message</b-button>
          </div>
        </b-form-group>

        <b-form-group
          label-for="note-textarea"
          class="mb-3"
        >
            <template slot="label">
                <div class="d-flex justify-content-between">
                    <div>Note: (Optional)</div>
                    <b-icon
                        class="cursor-pointer" 
                        v-b-popover.hover.top="'Note for people viewing this page and this holiday (meant for internal use).'"
                        icon="question-circle">
                    </b-icon>
                </div>
            </template>
          <b-form-textarea
            id="note-textarea"
            v-model="editRow.note"
            rows="3"
            required
          ></b-form-textarea>
        </b-form-group>

        <b-form-group
          label-for="enabled"
          class="mb-3"
        >
            <template slot="label">
                <div class="d-flex justify-content-between">
                    <div>This holiday is: </div>
                    <b-icon 
                        class="cursor-pointer" 
                        v-b-popover.hover.top="'A switch to enable this holiday voicemail or not on the specified start to end dates.'"
                        icon="question-circle">
                    </b-icon>
                </div>
            </template>
          <b-form-checkbox v-model="editRow.enabled" switch size="lg"><small>{{editRow.enabled ? 'Enabled' : 'Disabled'}}</small></b-form-checkbox>
        </b-form-group>
        
      </div>
    </b-modal>

    <b-modal
      size="lg"
      v-model="newModalShow"
      @hide="stopAudio"
      id="new-modal"
      :title="`Add New Holiday`"
    >
        <template v-slot:modal-footer="{ cancel }">
            <b-button size="sm" variant="primary" squared :disabled="!newFormValid || addingHoliday" @click="addHolidaySubmit">
                <b-icon v-if="addingHoliday" icon="arrow-clockwise" animation="spin" font-scale="1"></b-icon>
                Add Holiday
            </b-button>
            <b-button size="sm" squared variant="secondary" @click="cancel()">
                Cancel
            </b-button>
        </template>
      <div>
        <b-form-group
          label-for="holiday-input"
          invalid-feedback="Holiday name is required"
        >
            <template slot="label">
                <div class="d-flex justify-content-between">
                    <div>Holiday Name: (Required)</div>
                    <b-icon 
                        class="cursor-pointer" 
                        v-b-popover.hover.top="'Name of the holiday (just for identification purposes, not used anywhere else).'"
                        icon="question-circle">
                    </b-icon>
                </div>
            </template>
          <b-form-input
            id="holiday-input"
            v-model="newRow.holiday"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label-for="holiday-input"
          invalid-feedback="Holiday date is required"
          label-class="mb-1 mt-3"
          class="mb-3"
          description="Click twice: first click selects the start date, second click selects the end date."
        >
            <template slot="label">
                <div class="d-flex justify-content-between">
                    <div>Holiday Date (Start - End): (Required)</div>
                    <b-icon 
                        class="cursor-pointer" 
                        v-b-popover.hover.top="'The start and end date of the holiday (pick the same date for both if it\'s one day only). If this holiday is enabled, people calling the Amazon Connect number on the start and end date will hear the text in the Voicemail Message field playing as a speech before they hear a beep and their voicemail recorded.'"
                        icon="question-circle">
                    </b-icon>
                </div>
            </template>
            <v-date-picker
                mode='range'
                v-model='newRow.dateRange'
                :popover="{ placement: 'bottom', visibility: 'click' }"
                :disabled-dates="allDisabledDates"
                :attributes="newHolidayAttributes"
                :input-props="{readonly: true}"
                :masks="{ input: [dateFormat]}"
                color="purple"
            >
                
            </v-date-picker>
        </b-form-group>

        <b-form-group
            label-for="full-day-input"
            label-class="mb-1 mt-3"
            class="mb-3"
            description="Set this to disabled if you want the holiday to take full 24 hours of the date range"
        >
            <template slot="label">
                <div class="d-flex justify-content-between">
                    <div>Time ranged:</div>
                    <b-icon 
                        class="cursor-pointer" 
                        v-b-popover.hover.top="'Set this to disabled if you want the holiday to take full 24 hours of the date range'"
                        icon="question-circle">
                    </b-icon>
                </div>
            </template>
            <b-form-checkbox v-model="newRow.timeRanged" switch size="lg"><small>{{newRow.timeRanged ? 'Enabled' : 'Disabled'}}</small></b-form-checkbox>
        </b-form-group>
        <b-form-group
            label-for="start-time"
            invalid-feedback="Start time is required"
            label-class="mb-1 mt-3"
            class="mb-3"
            v-if="newRow.timeRanged"
        >
            <template slot="label">
                <div class="d-flex justify-content-between">
                    <div>Start Time: (Required) {{!newRow.dateRange ? '(Select Holiday Date first)' : ''}}</div>
                    <b-icon 
                        class="cursor-pointer" 
                        v-b-popover.hover.top="'The start time of the holiday. If this holiday is enabled, people calling the Amazon Connect number on the start and end date (starting on this time) will hear the text in the Voicemail Message field playing as a speech before they hear a beep and their voicemail recorded.'"
                        icon="question-circle">
                    </b-icon>
                </div>
            </template>
            <div>
                <vue-timepicker 
                    @input="checkNewRowStartTimeDone"
                    v-model="newRow.startTime"
                    :disabled="!newRow.dateRange" 
                    format="h:mm A"
                ></vue-timepicker>
            </div>
        </b-form-group>

        <template
            v-if="newRow.timeRanged"
        >
            <b-form-group
                label-for="start-time"
                invalid-feedback="End time is invalid"
                label-class="mb-1 mt-3"
                class="mb-3"
            >
                <template slot="label">
                    <div class="d-flex justify-content-between">
                        <div>End Time: (Required) {{!newRow.dateRange ? '(Select Holiday Date first)' : ''}}</div>
                        <b-icon 
                            class="cursor-pointer" 
                            v-b-popover.hover.top="'The end time of the holiday. If this holiday is enabled, people calling the Amazon Connect number on the start and end date (ending on this time) will hear the text in the Voicemail Message field playing as a speech before they hear a beep and their voicemail recorded.'"
                            icon="question-circle">
                        </b-icon>
                    </div>
                </template>
                <div>
                    <vue-timepicker 
                        v-model="newRow.endTime" 
                        format="h:mm A"
                        :disabled="!newRow.dateRange"
                        @input="checkNewRowTimeValid"
                    ></vue-timepicker>
                </div>
            </b-form-group>

            <div
                v-if="newRowTimeValidator"
                class="mb-5"
                :key="'new_' + JSON.stringify(newRow.dateRange)"
            >
                The holiday is set to start on <strong>{{prettyDate(newRow.dateRange.start)}}</strong> at <strong>{{prettyTime(newRow.startTime)}}</strong> and end on <strong>{{prettyDate(newRow.dateRange.end)}}</strong> at <strong>{{prettyTime(newRow.endTime)}}</strong>
            </div>
        </template>

        <b-form-group
        label-for="holiday-textarea"
        invalid-feedback="Voicemail message is required"
        class="mb-3"
        >
            <template slot="label">
                <div class="d-flex justify-content-between">
                    <div>Voicemail Message: (Required)</div>
                    <b-icon 
                        class="cursor-pointer" 
                        v-b-popover.hover.top="'The Voicemail Message which will be converted into speech that will play to people calling the Amazon Connect number on the start to end date (if this holiday is enabled) before they hear a beep and their voicemail recorded.'" 
                        icon="question-circle">
                    </b-icon>
                </div>
            </template>
        <b-form-textarea
            id="holiday-textarea"
            v-model="newRow.voicemailMessage"
            :disabled="generatingAudio || playingAudio"
            @input="audioStream = null"
            rows="4"
            required
        ></b-form-textarea>
        <div class="d-flex justify-content-end">
            <b-button v-if="!audioStream" :disabled="generatingAudio || !newRow.voicemailMessage.trim()" size="sm" class="mt-1 mr-1" @click="generateTextToSpeech(newRow.voicemailMessage)">
                <b-icon icon="volume-up"></b-icon> {{generatingAudio ? 'Generating Audio...' : 'Play Audio Preview'}}
            </b-button>

            <b-button v-if="audioStream" size="sm" :disabled="!newRow.voicemailMessage.trim()" class="mt-1 mr-1" @click="playStopAudioStream">
                <b-icon icon="volume-up"></b-icon> {{playingAudio ? 'Stop Audio' : 'Play Audio Preview'}}
            </b-button>
            <b-button @click="defaultVoicemailMessageConfirm(newRow)" class="mt-1" size="sm">Use Default Message</b-button>
        </div>
        </b-form-group>

        <b-form-group
          label-for="note-textarea"
          class="mb-3"
        >
            <template slot="label">
                <div class="d-flex justify-content-between">
                    <div>Note: (Optional)</div>
                    <b-icon
                        class="cursor-pointer" 
                        v-b-popover.hover.top="'Note for people viewing this page and this holiday (meant for internal use).'"
                        icon="question-circle">
                    </b-icon>
                </div>
            </template>
          <b-form-textarea
            id="note-textarea"
            v-model="newRow.note"
            rows="3"
            required
          ></b-form-textarea>
        </b-form-group>

        <b-form-group
          label-for="enabled"
          class="mb-3"
        >
            <template slot="label">
                <div class="d-flex justify-content-between">
                    <div>This holiday is: </div>
                    <b-icon 
                        class="cursor-pointer" 
                        v-b-popover.hover.top="'A switch to enable or disable this holiday voicemail for the specified start to end dates.'"
                        icon="question-circle">
                    </b-icon>
                </div>
            </template>
          <b-form-checkbox v-model="newRow.enabled" switch size="lg"><small>{{newRow.enabled ? 'Enabled' : 'Disabled'}}</small></b-form-checkbox>
        </b-form-group>
        
      </div>
    </b-modal>



  </div>
</template>

<script>
import { updateMainSwitch, updateRecord, createRecord, deleteRecord } from '@/graphql/mutations';
import { listRecords, getMainSwitch } from '@/graphql/queries';
import { API } from 'aws-amplify';
import { onCreateRecord, onUpdateRecord, onDeleteRecord, onUpdateMainSwitch } from '@/graphql/subscriptions';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat'
import Amplify, { Predictions } from 'aws-amplify';
import { AmazonAIPredictionsProvider } from '@aws-amplify/predictions';
import awsconfig from '../aws-exports';
Amplify.configure(awsconfig);

import {Howl} from 'howler';
import VueTimepicker from 'vue2-timepicker';
import 'vue2-timepicker/dist/VueTimepicker.css';

const region = 'Asia/Singapore';
const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');
const isBetween = require('dayjs/plugin/isBetween')

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.guess();
dayjs.tz.setDefault(region);
dayjs.tz(dayjs.tz().format('YYYY-MM-DD HH:mm:ss'), region);

dayjs.extend(customParseFormat);
const dbDateFormat = 'YYYY-MM-DD';
const timeFormat = 'h:mm A';
const dateFormat = dbDateFormat;
const dateToday = dayjs.tz().format(dateFormat);
dayjs.extend(isBetween)

const defaultVoicemailMessage = `Thank you for calling the dementia helpline. We are closed for the Public Holiday.

To leave a message please speak after the tone, and we will return your call on the next working day. Thank you.`

const dbMainSwitchId = "MainSwitch";


export default {
    name: 'MainTable',
    components: {
        VueTimepicker
    },
    async created(){
        Amplify.addPluggable(new AmazonAIPredictionsProvider());
        this.getRecords();
        this.getTheMainSwitch();
        this.subscribe();
    },
    data() {
        return {
            audioStream: null,
            generatingAudio: false,
            dateFormat,
            timeFormat,
            sortBy: "holidayStart",
            sortDesc: false,
            defaultVoicemailMessage,
            dbMainSwitchId,
            hideDisabledHolidaySwitch: false,
            addingHoliday: false,
            editingHoliday: false,
            items: [],
            timezone: region,
            fields: [
                { key: '#'},
                { key: 'holiday', label: 'Holiday', sortable: true },
                { key: 'holidayStart', label: 'Start', sortable: true },
                { key: 'holidayEnd', label: 'End', sortable: true },
                { key: 'days', label: 'Length', sortable: true },
                { key: 'voicemailMessage', label: 'Voicemail Message', sortable: true },
                { key: 'enabled', label: 'Enabled', sortable: true },
                { key: 'note', label: 'Note', sortable: false },
                { key: 'action', label: 'Action', sortable: false }    
            ],
            search: '',
            currentPage: 1,
            totalRows: null,
            perPage: 5,
            pageOptions: [5, 10, 15],
            editRow: {
                holiday: '',
                holidayStart: '',
                holidayEnd: '',
                voicemailMessage: defaultVoicemailMessage,
                enabled: true,
                note: '',
                dateRange: {
                    start: null,
                    end: null
                },
                timeRanged: false,
                startTime: null,
                endTime: null
            },
            newRow: {
                holiday: '',
                holidayStart: '',
                holidayEnd: '',
                voicemailMessage: defaultVoicemailMessage,
                enabled: true,
                note: '',
                dateRange: {
                    start: null,
                    end: null
                },
                timeRanged: false,
                startTime: null,
                endTime: null
            },
            editModalShow: false,
            editHolidayName: null,
            newModalShow: false,
            mainSwitch: false,
            mainSwitchGot: false,
            recordsGot: false,
            newRowStartTimeDone: false,
            newRowEndTimeDone: false,
            newTimeValid: true,
            editRowStartTimeDone: false,
            editRowEndTimeDone: false,
            editTimeValid: true
        }
    },
    watch: {
        isNewRowTimeValid(val){
            if (!val) {
                this.$bvModal.msgBoxOk('End time for the date range has to be after start time', {
                    title: 'Invalid time range',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    headerClass: 'p-2 border-bottom-0',
                    footerClass: 'p-2 border-top-0',
                    centered: true
                })
                .then(() => {
                    this.newRow.endTime = {
                        A: "",
                        h: "",
                        mm: ""
                    }
                })
                .catch(() => {
                    // An error occurred
                })
            }
        },
        isEditRowTimeValid(val){
            if (!val) {
                this.$bvModal.msgBoxOk('End time for the date range has to be after start time', {
                    title: 'Invalid time range',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    headerClass: 'p-2 border-bottom-0',
                    footerClass: 'p-2 border-top-0',
                    centered: true
                })
                .then(() => {
                    this.editRow.endTime = {
                        A: "",
                        h: "",
                        mm: ""
                    }
                })
                .catch(() => {
                    // An error occurred
                })
            }
        },
        mainSwitch(val){
            if (!val) {
                this.items.forEach((item, index) => {
                    const modedItem = item;
                    modedItem._rowVariant = 'dark';
                    this.$set(this.items, index, modedItem)
                })
            } else {
                this.items.forEach((item, index) => {
                    const modedItem = item;
                    if (this.isHolidayBetweenToday(item.holidayStart, item.holidayEnd) && item.enabled) {
                        modedItem._rowVariant = 'primary';
                    } else {
                        modedItem._rowVariant = '';
                    }
                    this.$set(this.items, index, modedItem)
                })
            }
        },
        'newRow.dateRange': {
            handler() {
                this.checkNewRowTimeValid();
            },
            deep: true
        },
        'editRow.dateRange': {
            handler() {
                this.checkEditRowTimeValid();
            },
            deep: true
        }
    },
    computed: {
        newRowTimeValidator(){
            if (this.newRow.timeRanged) {
                return this.newRow.startTime && this.newRow.startTime.h && this.newRow.startTime.mm && this.newRow.startTime.A && this.newRow.endTime && this.newRow.endTime.h && this.newRow.endTime.mm && this.newRow.endTime.A && this.isNewRowTimeValid
            }

            return true;
        },
        editRowTimeValidator(){
            if (this.editRow.timeRanged) {
                return this.editRow.startTime && this.editRow.startTime.h && this.editRow.startTime.mm && this.editRow.startTime.A && this.editRow.endTime && this.editRow.endTime.h && this.editRow.endTime.mm && this.editRow.endTime.A && this.isEditRowTimeValid
            }

            return true;
        },
        isEditRowTimeValid(){
            if (!this.editRowStartTimeDone || !this.editRowEndTimeDone) {
                return true;
            }

            return this.editTimeValid;
            
        },
        isNewRowTimeValid(){
            if (!this.newRowStartTimeDone || !this.newRowEndTimeDone) {
                return true;
            }

            return this.newTimeValid;
            
        },
        editRowDisabled(){
            return this.editRow.voicemailMessage && !this.editRow.voicemailMessage.trim()
        },
        playingAudio(){
            if (!this.audioStream)
                return false;

            return this.audioStream.playing();
        },
        playButtonText(){
            return this.audioStream.playing() ? 'Stop' : 'Play'
        },
        tableBusy(){
            return !this.mainSwitchGot || !this.recordsGot
        },
        selectDragAttribute() {
            return {
                popover: {
                    visibility: 'hover',
                    isInteractive: false,
                },
            };
        },
        newHolidayDisabledDates(){
            const final = [{
                start: null,
                end: dayjs().subtract(1, 'day').toDate()
            }];
            return final;
        },
        newHolidayDisabledDatesDetails(){
            const final = [];
            this.items.forEach(item => {
                final.push({
                    description: item.holiday,
                    isComplete: true,
                    dates: {
                        start: dayjs(item.holidayStart, dateFormat).toDate(),
                        end: dayjs(item.holidayEnd, dateFormat).toDate()
                    },
                    color: 'red'
                })
            });
            return final;
        },
        editHolidayDisabledDatesDetails(){
            const final = [];
            this.items.forEach(item => {
                if(item.id !== this.editRow.id) {
                    final.push({
                        description: item.holiday,
                        isComplete: true,
                        dates: {
                            start: dayjs(item.holidayStart, dateFormat).toDate(),
                            end: dayjs(item.holidayEnd, dateFormat).toDate()
                        },
                        color: 'red'
                    })
                }
            });
            return final;
        },
        newHolidayAttributes(){
            return [
                // Attributes for todos
                ...this.newHolidayDisabledDatesDetails.map(holiday => ({
                dates: holiday.dates,
                highlight: {
                    color: 'red'
                },
                popover: {
                    label: holiday.description,
                },
                customData: holiday,
                })),
            ];
        },
        editHolidayAttributes(){
            return [
                // Attributes for todos
                ...this.editHolidayDisabledDatesDetails.map(holiday => ({
                dates: holiday.dates,
                highlight: {
                    color: 'red'
                },
                popover: {
                    label: holiday.description,
                },
                customData: holiday,
                })),
            ];
        },
        editDisabledDates(){
            const final = [];
            this.items.forEach(item => {
                if (item.id !== this.editRow.id) {
                    final.push({
                        start: dayjs(item.holidayStart, dateFormat).toDate(),
                        end: dayjs(item.holidayEnd, dateFormat).toDate()
                    })
                }
            });
            return [...this.newHolidayDisabledDates, ...final];
        },
        allDisabledDates() {
            const final = [];
            this.items.forEach(item => {
                final.push({
                    start: dayjs(item.holidayStart, dateFormat).toDate(),
                    end: dayjs(item.holidayEnd, dateFormat).toDate()
                })
            });
            return [...this.newHolidayDisabledDates, ...final];
        },
        editFormValid(){
            const holiday = this.editRow.holiday;
            const holidayStart = this.editRow.dateRange && this.editRow.dateRange.start && dayjs(this.editRow.dateRange.start).format(dateFormat);
            const holidayEnd = this.editRow.dateRange && this.editRow.dateRange.end && dayjs(this.editRow.holidayEnd).format(dateFormat);
            const voicemailMessage = this.editRow.voicemailMessage;
            const timeValid = this.editRowTimeValidator;
            const allValid = holiday && holidayStart && holidayEnd && voicemailMessage && timeValid;
            return allValid;
        },
        newFormValid(){
            const holiday = this.newRow.holiday;
            const holidayStart = this.newRow.dateRange && this.newRow.dateRange.start && dayjs(this.newRow.holidayStart).format(dateFormat);
            const holidayEnd = this.newRow.dateRange && this.newRow.dateRange.end && dayjs(this.newRow.holidayEnd).format(dateFormat);
            const voicemailMessage = this.newRow.voicemailMessage;
            const timeValid = this.newRowTimeValidator;
            const allValid = holiday && holidayStart && holidayEnd && voicemailMessage && timeValid;
            return allValid;
        },
        app() {
            return this.$store.state.app
        },
        bp(){
            return this.$store.state.currentBreakpoint;
        },
        emptyText(){
            return this.search ? `No holiday record found matching search term "${this.search}"` : 'No holiday record found';
        },
        noPad(){
            return this.bp == 'sm' || this.bp == 'xs' ? 'padding: 0' : 'padding: 1.25rem';
        }
    },
    methods: {
        prettyTableStartDate(data) {
          return `${data.holidayStart} ${data.startTime.h ? `(Starts at ${this.prettyTime(data.startTime)})` : '(All day)'}`
        },
        prettyTableEndDate(data) {
          return `${data.holidayEnd} ${data.endTime.h ? `(Ends at ${this.prettyTime(data.endTime)})` : '(All day)'}`
        },
        prettyTime(time) {
            if (!time.h || !time.mm || !time.A) {
                return false;
            }

            return `${time.h}:${time.mm} ${time.A}`;
        },
        prettyDate(date) {
            return dayjs(date).format(`${dbDateFormat} (ddd)`);
        },
        checkNewRowStartTimeDone(e){
            let newRowStartTimeDone = false;
            if (e.A && e.h && e.mm) {
                newRowStartTimeDone = true;
            }
            this.newRowStartTimeDone = newRowStartTimeDone;
        },
        checkNewRowTimeValid() {
            let newRowEndTimeDone = false;
            if (this.newRow.endTime && this.newRow.endTime.A && this.newRow.endTime.h && this.newRow.endTime.mm) {
                newRowEndTimeDone = true;
            }
            this.newRowEndTimeDone = newRowEndTimeDone;

            if (this.newRowStartTimeDone) {
                let startDate = this.getDateFromTime(this.newRow.dateRange.start, this.newRow.startTime);
                let endDate = this.getDateFromTime(this.newRow.dateRange.end, this.newRow.endTime);

                if (!startDate || !endDate) {
                    this.newTimeValid = false;
                    return;
                }

                if (startDate.isBefore(endDate)) {
                    this.newTimeValid = true;

                    return;
                }
                this.newTimeValid = false;


            }
        },
        getDateFromTime(date, time){
            let hour, minute;
            if (time.A == "AM") {
                hour  = +time.h == 12 ? 0 : +time.h;
            } else if (time.A == "PM") {
                hour = +time.h < 12 ? +time.h + 12 : +time.h;
            }

            minute = +time.mm;

            return dayjs(date).set('hour', hour).set('minute', minute);
        },
        checkEditRowStartTimeDone(e){
            let editRowStartTimeDone = false;
            if (e.A && e.h && e.mm) {
                editRowStartTimeDone = true;
            }
            this.editRowStartTimeDone = editRowStartTimeDone;
        },
        checkEditRowTimeValid() {
            let editRowEndTimeDone = false;
            if (this.editRow.endTime && this.editRow.endTime.A && this.editRow.endTime.h && this.editRow.endTime.mm) {
                editRowEndTimeDone = true;
            }
            this.editRowEndTimeDone = editRowEndTimeDone;

            if (this.editRowStartTimeDone) {
                let startDate = this.getDateFromTime(this.editRow.dateRange.start, this.editRow.startTime);
                let endDate = this.getDateFromTime(this.editRow.dateRange.end, this.editRow.endTime);

                if (!startDate || !endDate) {
                    this.editTimeValid = false;
                    return;
                }

                if (startDate.isBefore(endDate)) {
                    this.editTimeValid = true;

                    return;
                }
                this.editTimeValid = false;


            }
        },        
        beforeDestroy(){
            this.playStopAudioStream
        },
        stopAudio(){
            if (this.audioStream && this.audioStream.playing()) {
                this.audioStream.stop();
            }
            this.audioStream = null;
        },
        base64ArrayBuffer(arrayBuffer) {
            let base64    = ''
            let encodings = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/'

            let bytes         = new Uint8Array(arrayBuffer)
            let byteLength    = bytes.byteLength
            let byteRemainder = byteLength % 3
            let mainLength    = byteLength - byteRemainder

            let a, b, c, d
            let chunk

            for (let i = 0; i < mainLength; i = i + 3) {
                chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2]
                a = (chunk & 16515072) >> 18 // 16515072 = (2^6 - 1) << 18
                b = (chunk & 258048)   >> 12 // 258048   = (2^6 - 1) << 12
                c = (chunk & 4032)     >>  6 // 4032     = (2^6 - 1) << 6
                d = chunk & 63               // 63       = 2^6 - 1
                base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d]
            }

            if (byteRemainder == 1) {
                chunk = bytes[mainLength]
                a = (chunk & 252) >> 2 // 252 = (2^6 - 1) << 2
                b = (chunk & 3)   << 4 // 3   = 2^2 - 1
                base64 += encodings[a] + encodings[b] + '=='
            } else if (byteRemainder == 2) {
                chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1]
                a = (chunk & 64512) >> 10 // 64512 = (2^6 - 1) << 10
                b = (chunk & 1008)  >>  4 // 1008  = (2^6 - 1) << 4
                c = (chunk & 15)    <<  2 // 15    = 2^4 - 1
                base64 += encodings[a] + encodings[b] + encodings[c] + '='
            }
            
            return base64
            },
        playStopAudioStream(){
            this.audioStream.playing() ? this.audioStream.stop() : this.audioStream.play();
        },
        generateTextToSpeech(text) {
            this.generatingAudio = true;
            Predictions.convert({
            textToSpeech: {
                source: {
                    text: text,
                },
                voiceId: "Joanna" // default configured on aws-exports.js 
                // list of different options are here https://docs.aws.amazon.com/polly/latest/dg/voicelist.html
            }
            }).then(result => {
            
                this.generatingAudio = false;
                this.audioStream = new Howl({
                    src: ["data:audio/mp3;base64,"+this.base64ArrayBuffer(result.audioStream)],
                });

                this.audioStream.play();
            
            })
            .catch(err => {
                console.log(err);
                alert(err);
            });
        },
        defaultVoicemailMessageConfirm(target){
            const message = !target.voicemailMessage ? `Are you sure you want to use the default public holiday voicemail message? (${defaultVoicemailMessage})` : `Are you sure you want to overwrite the current voicemail message to the default public holiday voicemail message? (${defaultVoicemailMessage})`;

            this.$root.confirm({
                message,
                title: `Confirm to use default voicemail message`,
                okVariant: 'primary',
                okTitle: 'Confirm',
                cancelTitle: 'Cancel'
            }, 
                () => this.defaultingVoicemailMessage(target)
            )
        },
        defaultingVoicemailMessage(target) {
            target.voicemailMessage = defaultVoicemailMessage;
        },
        filterTable(original, search){
            const hideDisabled = this.hideDisabledHolidaySwitch;
            const standardSearch = search ? search.toLowerCase() : '';


            const values = Object.values(original).map(v => {
                if (typeof v == 'boolean')
                    return v ? 'yes' : 'no';

                return v;
            }).join('|').toLowerCase();

            if (hideDisabled) {
                if (!original.enabled) return false
                if (values.includes(standardSearch)) return true

            } else {
                if (values.includes(standardSearch)) return true
            }
            return false;
        },
        isHolidayBetweenToday(holidayStart, holidayEnd){
            return dayjs(dateToday, dateFormat).isBetween(dayjs(holidayStart, dateFormat), dayjs(holidayEnd, dateFormat), null, '[');
        },
        confirmSwitch(){
            const doing = this.mainSwitch ? 'disable' : 'enable'
            const message = doing == 'disable' ? `If you switch off the holiday switch, none of your calls will be routed to voicemail during regular working hours.` : `Calls will be routed to voicemail if they match the dates in the holidays record table (if status is enabled).`;
            const doingFirstLetterUppercase = doing.charAt(0).toUpperCase() + doing.slice(1);
            this.$root.confirm({
                message,
                title: `${doingFirstLetterUppercase} Holiday Switch?`,
                okVariant: 'primary',
                okTitle: 'Confirm',
                cancelTitle: 'Cancel'
            }, 
            () => this.changeMainSwitch(this.mainSwitch),
            () => {
                this.mainSwitch = !this.mainSwitch
            }
            );
        },
        async changeMainSwitch(val){
            try{
                await API.graphql({
                    query: updateMainSwitch,
                    variables: {
                        input: {
                            id: this.dbMainSwitchId,
                            enabled: val
                        },
                    },
                });
                this.$bvToast.toast('Successfully changed the Holiday Check Switch', {
                    title: `Success`,
                    variant: 'primary',
                    solid: true
                });
            } catch (error) {
                this.$bvToast.toast('Error: ' + error, {
                    title: `Error changing the Holiday Check Switch`,
                    variant: 'danger',
                    solid: true
                });
            }
        },
        async editHolidaySubmit(){
            if (!this.editFormValid) {
                return;
            }

            this.stopAudio();
            this.editingHoliday = true;

            const e = this.editRow
            let prepared = {
                id: e.id,
                holiday: e.holiday,
                holidayStart: dayjs(e.dateRange.start).format(dbDateFormat),
                holidayEnd: dayjs(e.dateRange.end).format(dbDateFormat),
                voicemailMessage: e.voicemailMessage,
                note: e.note,
                enabled: e.enabled,
                expdate: dayjs(e.dateRange.end).add(2, 'day').unix(),
            }

            if (e.timeRanged) {
                let startTime = this.getDateFromTime(e.dateRange.start, e.startTime);
                let endTime = this.getDateFromTime(e.dateRange.end, e.endTime);
                prepared.startTime = startTime.toISOString();
                prepared.endTime = endTime.toISOString();
                prepared.timeRanged = true;
            } else {
                prepared.startTime = '';
                prepared.endTime = '';
                prepared.timeRanged = false;
            }

            try {
                await API.graphql({
                    query: updateRecord,
                    variables: {
                        input: prepared,
                    },
                });
                this.$bvToast.toast('Holiday record has been edited successfully!', {
                    title: `Success`,
                    variant: 'primary',
                    solid: true
                });
                this.editingHoliday = false;
                this.$bvModal.hide('edit-modal')
            } catch(error) {
                this.$bvToast.toast('Error: ' + error, {
                    title: `Error editing record`,
                    variant: 'danger',
                    solid: true
                });
                this.editingHoliday = false;
                this.$bvModal.hide('edit-modal')
            }
        },
        async addHolidaySubmit(){
            if (!this.newFormValid) {
                return;
            }

            this.stopAudio();
            this.addingHoliday = true;

            const a = this.newRow;
            let prepared = {
                id: a.id,
                holiday: a.holiday,
                holidayStart: dayjs(a.dateRange.start).format(dbDateFormat),
                holidayEnd: dayjs(a.dateRange.end).format(dbDateFormat),
                voicemailMessage: a.voicemailMessage,
                note: a.note,
                enabled: a.enabled,
                expdate: dayjs(a.dateRange.end).add(2, 'day').unix(),
            }

            if (a.timeRanged) {
                let startTime = this.getDateFromTime(a.dateRange.start, a.startTime);
                let endTime = this.getDateFromTime(a.dateRange.end, a.endTime);
                prepared.startTime = startTime.toISOString();
                prepared.endTime = endTime.toISOString();
                prepared.timeRanged = true;
            } else {
                prepared.startTime = '';
                prepared.endTime = '';
                prepared.timeRanged = false;
            }

            try {
                await API.graphql({
                    query: createRecord,
                    variables: {
                        input: prepared,
                    },
                });
                this.$bvToast.toast('Holiday record has been added successfully!', {
                    title: `Success`,
                    variant: 'primary',
                    solid: true
                });
                this.addingHoliday = false;

                this.newRow = {
                    holiday: '',
                    holidayStart: '',
                    holidayEnd: '',
                    voicemailMessage: defaultVoicemailMessage,
                    enabled: true,
                    note: '',
                    dateRange: null,
                    startTime: null,
                    endTime: null,
                    timeRanged: false
                }

                this.$bvModal.hide('new-modal')
            } catch(error) {
                this.$bvToast.toast('Error: ' + error, {
                    title: `Error adding record`,
                    variant: 'danger',
                    solid: true
                });
                this.addingHoliday = false;
                this.$bvModal.hide('new-modal')
            }
        },
        removeConfirm(row){
            this.$root.confirm({
                message: 'Are you sure you want to remove this holiday record?',
                title: 'Confirm',
                okVariant: 'danger',
                okTitle: 'Confirm',
                cancelTitle: 'Cancel'
            }, () => this.remove(row.id))
        },
        async remove(id){
            try {
                await API.graphql({
                    query: deleteRecord,
                    variables: {
                        input: {
                            id,
                        },
                    },
                });
                this.$bvToast.toast('Holiday record has been removed successfully!', {
                    title: `Success`,
                    variant: 'primary',
                    solid: true
                });
            } catch (error) {
                this.$bvToast.toast('Error: ' + error, {
                    title: `Error removing record`,
                    variant: 'danger',
                    solid: true
                });
            }
        },
        editHoliday(row){
            this.editRow = Object.assign({}, this.editRow, JSON.parse(JSON.stringify(row)));
            this.editRow.dateRange = {
                start: dayjs(row.holidayStart, dateFormat).toDate(),
                end: dayjs(row.holidayEnd, dateFormat).toDate()
            }
            this.editHolidayName = '"' + JSON.parse(JSON.stringify(row)).holiday + '"';
            this.editModalShow = true;
        },
        addHoliday(){
            this.newModalShow = true;
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        subscribe() {
                API.graphql({ query: onCreateRecord })
                    .subscribe({
                        next: (eventData) => {
                            let record = eventData.value.data.onCreateRecord;
                            const newItem = this.processRecords([record]);
                            this.items.push(...newItem);
                            this.totalRows = this.items.length
                        },
                        error: error => console.dir(JSON.stringify(error)),
                    });
                API.graphql({ query: onUpdateRecord })
                    .subscribe({
                        next: (eventData) => {
                            const record = eventData.value.data.onUpdateRecord;
                            const updatedItem = this.processRecords([record]);
                            this.items.forEach((item, index) => {
                                if (item.id === record.id) {
                                    this.$set(this.items, index, ...updatedItem);
                                }
                            });
                        },
                        error: error => console.dir(JSON.stringify(error)),
                    });

                API.graphql({ query: onDeleteRecord })
                    .subscribe({
                        next: (eventData) => {
                            const record = eventData.value.data.onDeleteRecord;
                            this.items.forEach((item, index) => {
                                if (item.id === record.id) {
                                    this.$delete(this.items, index);
                                }
                            });
                            this.totalRows = this.items.length
                        },
                        error: error => console.dir('asf' + error),
                    });

                API.graphql({ query: onUpdateMainSwitch })
                    .subscribe({
                        next: (eventData) => {
                            const mainSwitch = eventData.value.data.onUpdateMainSwitch;
                            this.mainSwitch = Boolean(mainSwitch.enabled)
                        },
                        error: error => console.dir(JSON.stringify(error)),
                });
        },
        async getRecords() {
            const records = await API.graphql({
                query: listRecords
            });
            this.recordsGot = true;
            this.items = this.processRecords(records.data.listRecords.items);
            this.totalRows = this.items.length
        },
        async getTheMainSwitch() {
            const mainSwitch = await API.graphql({
                query: getMainSwitch,
                variables: {
                    id: this.dbMainSwitchId
                }
            });
            this.mainSwitch = mainSwitch.data.getMainSwitch.enabled;
            this.mainSwitchGot = true;
        },
        async getMasterSwitch(){
            await API.graphql({
                query: listRecords
            });
        },
        isValidJSON(str) {
            try {
                return (JSON.parse(str) && !!str);
            } catch (e) {
                return false;
            }
        },
        truncate: (input, len = 30) => input && input.length > len ? `${input.substring(0, len)}...` : input,
        processRecords(records){
            return records.map(item => {
                const holidayStart = dayjs(item.holidayStart, dbDateFormat);
                const holidayEnd = dayjs(item.holidayEnd, dbDateFormat);
                let days = holidayEnd.diff(holidayStart, 'day');
                days = days + 1;

                let highlighted = '';
                const readableHolidayStart = holidayStart.format(dateFormat);
                const readableHolidayEnd = holidayEnd.format(dateFormat);
                if (this.isHolidayBetweenToday(readableHolidayStart, readableHolidayEnd) && item.enabled) {
                    highlighted = 'primary';
                }

                let obj = {
                    id: item.id,
                    holiday: item.holiday,
                    holidayStart: readableHolidayStart,
                    holidayEnd: readableHolidayEnd,
                    voicemailMessage: item.voicemailMessage,
                    days: days + ' day' + (days > 1 ? 's' : ''),
                    note: item.note,
                    timeRanged: item.timeRanged,
                    enabled: item.enabled,
                    _rowVariant: this.mainSwitch ? highlighted : 'dark'
                }

                if (item.timeRanged) {
                    const startTime = dayjs(item.startTime);
                    const endTime = dayjs(item.endTime);

                    obj.startTime =  {
                        h: startTime.format('h').toString(),
                        mm: startTime.format('mm').toString(),
                        A: startTime.format('A')
                    }

                    obj.endTime = {
                        h: endTime.format('h').toString(),
                        mm: endTime.format('mm').toString(),
                        A: endTime.format('A')
                    }

                } else {
                    obj.startTime = {};
                    obj.endTime = {};
                }

                return obj;
            }).filter(item => !dayjs(dateToday, dateFormat).isAfter(dayjs(item.holidayEnd, dateFormat)));
        }
    }
}
</script>

<style lang="scss">
    $primary: #655aaf;

    thead {
        font-size: smaller;
    }
    
    .min-width-1037 {
        min-width: 1037px;
    }

    .cursor-pointer {
        cursor: pointer;
    }

    .vue__time-picker * {
        font-family: 'montserrat';
    }

    .vue__time-picker .dropdown ul li:not([disabled]).active,
    .vue__time-picker .dropdown ul li:not([disabled]).active:hover,
    .vue__time-picker .dropdown ul li:not([disabled]).active:focus,
    .vue__time-picker-dropdown ul li:not([disabled]).active,
    .vue__time-picker-dropdown ul li:not([disabled]).active:hover,
    .vue__time-picker-dropdown ul li:not([disabled]).active:focus {
    background: $primary;
    color: #fff;
    }

    .vue__time-picker.time-picker input {
        border-radius: 4px;
    }
</style>