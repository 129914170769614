/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getRecord = /* GraphQL */ `
  query GetRecord($id: ID!) {
    getRecord(id: $id) {
      id
      holiday
      holidayStart
      holidayEnd
      voicemailMessage
      enabled
      note
      startTime
      endTime
      timeRanged
      expdate
      createdAt
      updatedAt
    }
  }
`;
export const listRecords = /* GraphQL */ `
  query ListRecords(
    $filter: ModelRecordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRecords(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        holiday
        holidayStart
        holidayEnd
        voicemailMessage
        enabled
        note
        startTime
        endTime
        timeRanged
        expdate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMainSwitch = /* GraphQL */ `
  query GetMainSwitch($id: ID!) {
    getMainSwitch(id: $id) {
      id
      enabled
      createdAt
      updatedAt
    }
  }
`;
export const listMainSwitchs = /* GraphQL */ `
  query ListMainSwitchs(
    $filter: ModelMainSwitchFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMainSwitchs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        enabled
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
