/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateRecord = /* GraphQL */ `
  subscription OnCreateRecord {
    onCreateRecord {
      id
      holiday
      holidayStart
      holidayEnd
      voicemailMessage
      enabled
      note
      startTime
      endTime
      timeRanged
      expdate
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateRecord = /* GraphQL */ `
  subscription OnUpdateRecord {
    onUpdateRecord {
      id
      holiday
      holidayStart
      holidayEnd
      voicemailMessage
      enabled
      note
      startTime
      endTime
      timeRanged
      expdate
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteRecord = /* GraphQL */ `
  subscription OnDeleteRecord {
    onDeleteRecord {
      id
      holiday
      holidayStart
      holidayEnd
      voicemailMessage
      enabled
      note
      startTime
      endTime
      timeRanged
      expdate
      createdAt
      updatedAt
    }
  }
`;
export const onCreateMainSwitch = /* GraphQL */ `
  subscription OnCreateMainSwitch {
    onCreateMainSwitch {
      id
      enabled
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateMainSwitch = /* GraphQL */ `
  subscription OnUpdateMainSwitch {
    onUpdateMainSwitch {
      id
      enabled
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteMainSwitch = /* GraphQL */ `
  subscription OnDeleteMainSwitch {
    onDeleteMainSwitch {
      id
      enabled
      createdAt
      updatedAt
    }
  }
`;
