/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_cognito_identity_pool_id": "ap-southeast-1:e028f449-f7e8-49c7-9813-9c56700f2661",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_pVBYMqmE6",
    "aws_user_pools_web_client_id": "ade1lrrnom0r2lhturbaac2pj",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://36dchnivbrcolgahkd656ch7ha.appsync-api.ap-southeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_content_delivery_bucket": "adaholidayswitch-web-dev",
    "aws_content_delivery_bucket_region": "ap-southeast-1",
    "aws_content_delivery_url": "https://d227vnl8rgu6bo.cloudfront.net",
    "predictions": {
        "convert": {
            "speechGenerator": {
                "region": "ap-southeast-1",
                "proxy": false,
                "defaults": {
                    "VoiceId": "Joanna",
                    "LanguageCode": "en-US"
                }
            }
        }
    }
};


export default awsmobile;
